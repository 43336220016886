// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albums-js": () => import("./../../../src/pages/albums.js" /* webpackChunkName: "component---src-pages-albums-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-category-single-js": () => import("./../../../src/templates/categorySingle.js" /* webpackChunkName: "component---src-templates-category-single-js" */),
  "component---src-templates-work-single-js": () => import("./../../../src/templates/workSingle.js" /* webpackChunkName: "component---src-templates-work-single-js" */)
}

