module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["es","en"],"defaultLanguage":"es","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sol Etchegaray","short_name":"Sol Etchegaray","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/gatsby_icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"712e187baa9fddb68b347fa2d510bff4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
